<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>考试名称：</span>
            <input v-model="pageInfo.name" placeholder="考试名称" class="searchInput" />
          </div>
          <div class="marginLeft10">
            <span>考试模式：</span>
            <el-select v-model="pageInfo.infinite"
                       class="m-2"
                       placeholder="选择考试模式"
                       size="large">
              <el-option label="单次模式" :value="0" />
              <el-option label="无限模式" :value="1" />
            </el-select>
          </div>
          <div class="marginLeft10">
            <span>试卷：</span>
            <el-select v-model="pageInfo.paperId"
                       filterable
                       class="m-2"
                       placeholder="选择试卷"
                       size="large">
              <el-option v-for="item in examPaperOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </div>
          <!--<div class="marginLeft10">-->
          <!--  <span>开始时间：</span>-->
          <!--  <el-date-picker v-model="pageInfo.startTime"-->
          <!--                  type="datetime"-->
          <!--                  size="large"-->
          <!--                  placeholder="选择考试开始时间"-->
          <!--                  value-format="YYYY-MM-DD HH:mm:ss"/>-->
          <!--</div>-->
          <!--<div class="marginLeft10">-->
          <!--  <span>结束时间：</span>-->
          <!--  <el-date-picker v-model="pageInfo.endTime"-->
          <!--                  type="date"-->
          <!--                  size="large"-->
          <!--                  placeholder="选择考试结束时间"-->
          <!--                  value-format="YYYY-MM-DD"/>-->
          <!--</div>-->
          <div>
            <button class="searchBtn" @click="search">搜索</button>
            <button class="resetBtn" @click="reset(null)">重置</button>
          </div>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">考试查询</div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="id" label="编号" />
          <el-table-column prop="name" label="考试名称" />
          <el-table-column prop="paper.name" label="考试试卷" />
          <el-table-column prop="endTime" label="考试模式">
            <template #default="{row}">
              <div> {{ row.infinite == 0 ? '单次模式' : '无限模式' }} </div>
            </template>
          </el-table-column>
          <el-table-column prop="startTime" label="开始考试时间" />
          <el-table-column prop="endTime" label="考试结束时间" />
          <el-table-column prop="time" label="创建时间" />
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="queryUsers(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOverPage, getPaperAll } from "@/api/examination";
import { floatParse } from "@/utils/common";
export default {
  name: "examResultList",
  data(){
    return{
      tableData:[],
      pageInfo:{
        limit:10,
        page:1,
        total:0,
        // -------------------
        name: '',
        infinite: '',
        startTime: '',
        endTime: '',
        paperId: '',
      },
      // ------------------------------------
      // 考试选项
      examPaperOptions: []
    }
  },
  mounted() {
    this.getList()
    this.getOption()
  },
  methods:{
    floatParse,
    /**
     * 查询考试学生
     */
    queryUsers(row){
      this.$root.useRouter.push({ path:`/examUser/list/${row.id}` })
    },
    /**
     * 获取考试
     */
    getOption(){
      getPaperAll().then(res => {
        res.data.forEach(iter => {
          this.examPaperOptions.push({
            label: iter.name,
            value: iter.id
          })
        })
      })
    },
    /**
     * 重置个别字段
     */
    reset(){
      const whitelist = ['limit', 'page', 'total']
      for (let key in this.pageInfo) {
        if (!whitelist.includes(key)) {
          this.pageInfo[key] = ''
        }
      }
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getOverPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    }
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>